



















import {Vue, Component} from "vue-property-decorator";
import {InfoControl} from "@/request/merchant/InfoControl";

@Component({
    name: 'merchant_infoControl'
})
export default class merchant_infoControl extends Vue {
    loadings: Array<any> = [
        {
            l1: false
        }
    ];
    height: number = document.body.clientHeight - 130;
    width: number = document.body.clientWidth - 260;
    info: any = {
        bstoreShow: false
    };
    isUseWalletPay: number = 0;

    created() {
        let self: any = this;
        this.getSetting();
    }

    getSetting() {
        this.loadings[0].l1 = true;
        InfoControl.getSetting().then((body: any) => {
            this.loadings[0].l1 = false;
            this.info = body.data;
        })
    }

    bstoreShowSubmit() {
        this.loadings[0].l1 = true;
        InfoControl.setSetting(this.info).then((body: any) => {
            this.loadings[0].l1 = false;
            this.$Message.info('保存成功');
        })
    }
}
