import {BaseRequest} from "@/request/BaseRequest";

class InfoControl extends BaseRequest {

    public requestPath: string = '/merchant/infoControl';

    /**
     * 获取设置
     */
    getSetting(): any {
        return this.get(`${this.requestPath}/get`)
    }

    /**
     * 设置
     * @param data
     */
    setSetting(data: any): any {
        return this.post(`${this.requestPath}/set`, data)
    }

}

const c = new InfoControl();
export {c as InfoControl};